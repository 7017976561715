import { format } from "date-fns"
import React from "react"
import { SidebarLayout } from "../components/layout"

export const Competition: React.FC<any> = ({ pageContext }) => {
  const { competitionSlug, competition, updates, sidebar } = pageContext
  const startDate = format(new Date(competition.start_date), "iii do LLL yyyy")
  const endDate = format(new Date(competition.end_date), "iii do LLL yyyy")

  return (
    <SidebarLayout
      sidebar={{
        competitionSlug,
        navigation: sidebar.navigation,
      }}
      competition={competition}
    >
      <div className="px-5">
        <div className="mb-8">
          <h2 className="font-primary font-black text-3xl">
            {competition.title.text}
          </h2>
          <time>
            {startDate} - {endDate}
          </time>
        </div>
        {updates.map(update => (
          <div className="mb-3 pb-3 border-b border-teal">
            <h2 className="font-primary font-black text-lg mb-2">
              {update.title}
            </h2>
            <div
              className="cmsContent"
              dangerouslySetInnerHTML={{ __html: update.content }}
            />
          </div>
        ))}
      </div>
    </SidebarLayout>
  )
}

export default Competition
